import { Button } from 'Components/shared/Button';
import { Heading } from 'Components/shared/Heading';
import { usePreferences } from 'Hooks/preferences/usePreferences'; // Import the hook
import { MobXProviderContext, observer } from 'mobx-react';
import React from 'react';
import type { CheckboxProps } from 'semantic-ui-react';

import type { RootStoreProps } from 'Stores/RootStore.types';
import { pushToGTMDataLayer } from 'Utils/analytics';
import { Styled } from './index.styles';

export const NotificationsSettings: React.FC = observer(() => {
  const testid = 'notificationSettings';
  const { ui, notification, phoneCall } =
    React.useContext<RootStoreProps>(MobXProviderContext);
  const { preferences, preferencesQuery, updatePreferences } = usePreferences();

  React.useEffect(() => {
    void ui.getOnlyMentions();
    phoneCall.updateEnableIncomingCalls();
  }, []);

  if (preferencesQuery.isPending) return <div>Loading...</div>;
  if (preferencesQuery.isError) return <div>Error loading preferences</div>;

  const onToggleShowOnlyDirectMentions = (_e, data: CheckboxProps) => {
    pushToGTMDataLayer('toggleShowOnlyDirectMentions');

    updatePreferences.mutate({
      directMentionsOnly: !preferences.directMentionsOnly,
    });
  };

  const onToggleShowIncomingCalls = (_e, data: CheckboxProps) => {
    phoneCall.setEnableIncomingCalls(!!data.checked);
    pushToGTMDataLayer('toggleShowIncomingCalls');
  };

  const onToggleDesktopNotifications = (_e, data: CheckboxProps) => {
    // If the Prompt (at the top of the app) is still showing, dismiss it
    notification.setWebNotificationPromptActionTaken(true);

    data.checked
      ? notification.tryEnableWebNotifications()
      : notification.setWebNotificationsEnabled(false);

    pushToGTMDataLayer('toggleDesktopNotifications');
  };

  const onTestDesktopNotificationClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    notification.addWebNotification(
      'Test!',
      {
        tag: 'test:test-desktop-notif',
        silent: true,
        body: 'This is a test notification. If you see this, desktop notifications are working! You rock! (This notification will be dismissed automatically)',
        icon: 'https://www.gravatar.com/avatar/?d=mm',
        timeout: 3000,
      },
      '',
      false,
      true
    );
  };

  return (
    <div className="settings-notifications">
      <div>
        <Heading variant="h2">Notifications</Heading>
        <Styled.ToggleButton
          label="Enable only direct mentions (includes @here)"
          checked={preferences.directMentionsOnly}
          onChange={onToggleShowOnlyDirectMentions}
          helperText="Enable group notification only if you are explicitly mentioned"
          testid={`${testid}-toggleButtonEnableOnlyDirectMentions`}
        />
        <Styled.ToggleButton
          label="Enable incoming calls"
          checked={phoneCall?.enableIncomingCalls}
          onChange={onToggleShowIncomingCalls}
          testid={`${testid}-toggleButtonEnableIncomingCalls`}
        />
        <Styled.ToggleButton
          label="Enable notification sounds"
          checked={preferences.notificationAudio}
          onChange={() =>
            updatePreferences.mutate({
              notificationAudio: !preferences.notificationAudio,
            })
          }
          testid={`${testid}-toggleButtonEnableNotificationSounds`}
        />
        <Styled.ToggleButton
          label="Enable desktop notifications"
          checked={notification.webNotificationsEnabled}
          onChange={onToggleDesktopNotifications}
          helperText="Enable desktop and application notifications when new message arrives"
          testid={`${testid}-toggleButtonEnableDesktopNotifications`}
        />
      </div>
      <Button
        variant="primary"
        disabled={!notification.webNotificationsEnabled}
        content="Test desktop notifications"
        onClick={onTestDesktopNotificationClick}
        testid={`${testid}-buttonTestDesktopNotifications`}
      />
    </div>
  );
});
