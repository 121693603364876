import { QueryClient } from '@tanstack/react-query';
import { BV_ENV_PRODUCTION } from 'Constants/env';
import { History } from 'history';
import {
  ConfigStore,
  ContactStore,
  ConversationStore,
  MessageStore,
  NotificationStore,
  ParticipantStore,
  PersonStore,
  PhoneStore,
  PreferenceStore,
  PusherStore,
  RouterStore,
  SearchStore,
  UiStore,
} from 'Stores/index';
import { bugsnagClient } from 'Utils/logUtils';
import CalendarStore from './CalendarStore';
import CallLogsStore from './CallLogsStore';

export class RootStore {
  constructor(browserHistory: History, queryClient: QueryClient) {
    this.notificationStore = new NotificationStore(this);
    this.routerStore = new RouterStore(browserHistory, this);
    this.personStore = new PersonStore(this, queryClient);
    this.contactStore = new ContactStore(this);
    this.participantStore = new ParticipantStore(this);
    this.messageStore = new MessageStore(this);
    this.conversationStore = new ConversationStore(this);
    this.searchStore = new SearchStore(this);
    this.phoneStore = new PhoneStore(this);
    this.pusherStore = new PusherStore(this, queryClient);
    this.uiStore = new UiStore(this);
    this.preferenceStore = new PreferenceStore(queryClient);
    this.configStore = new ConfigStore(this);
    this.calendarStore = new CalendarStore(this);
    this.callLogsStore = new CallLogsStore(this);
    if (!BV_ENV_PRODUCTION) {
      window['rootStore'] = this;
    }
  }

  readonly callLogsStore: CallLogsStore;
  readonly calendarStore: CalendarStore;
  readonly contactStore: ContactStore;
  readonly conversationStore: ConversationStore;
  readonly messageStore: MessageStore;
  readonly notificationStore: NotificationStore;
  readonly participantStore: ParticipantStore;
  readonly personStore: PersonStore;
  readonly phoneStore: PhoneStore;
  readonly pusherStore: PusherStore;
  readonly routerStore: RouterStore;
  readonly searchStore: SearchStore;
  readonly uiStore: UiStore;
  readonly preferenceStore: PreferenceStore;
  readonly configStore: ConfigStore;

  /**
   * This method calls the `clearAllData` method on each store in parallel.
   * It returns a promise that resolves when all the `clearAllData` calls have completed.
   * @returns {Promise<void[]>} A promise that resolves when all data has been cleared.
   * @remarks
   * **WARNING:** Order matters, be _very_ careful if you re-order this list for some reason.
   *
   * If you create a new store, it's probably safe to put it at the top of the list, since it isn't as
   * likely that the new store is used by the existing ones.
   */
  clearAllData = async () => {
    try {
      bugsnagClient.leaveBreadcrumb('Clearing all data');
      // Perform the operation that requires authentication here
      await this.conversationStore.clearAllData();
      await Promise.race([
        Promise.all([
          this.notificationStore.clearAllData(),
          this.messageStore.clearAllData(),
          this.contactStore.clearAllData(),
          this.participantStore.clearAllData(),
          this.phoneStore.clearAllData(),
          this.pusherStore.clearAllData(),
          this.searchStore.clearAllData(),
          this.uiStore.clearAllData(),
          this.personStore.clearAllData(),
          this.preferenceStore.clearAllData(),
          this.configStore.clearAllData(),
          this.calendarStore.clearAllData(),
          this.callLogsStore.clearAllData(),
        ]),
        new Promise((_, reject) => {
          setTimeout(() => {
            bugsnagClient.leaveBreadcrumb('Timeout - Slow Logout');
            reject(new Error('Clear all data timed out'));
          }, 3000);
        }),
      ]);
    } catch (error) {
      const errMsg = 'Error clearing all data';
      bugsnagClient.notify(errMsg, (event) => {
        event.severity = 'error';
        event.context = 'RootStore.clearAllData';
        event.addMetadata('custom', { function: 'clearAllData' });
      });
      throw error;
    }
  };

  transitionToLogout = async () => {
    this.personStore.setIsAutoLogout(true);
    this.routerStore.replace('/');
    await this.clearAllData();
  };
}
