import { INTERCOM_SETTINGS } from '../constants/env';
import { IPersonSignInTokenResponse } from '../models/PersonModel';
import { extractVersions } from './versionExtractor';

declare global {
  interface Window {
    Intercom?: (...args: any[]) => void;
  }
}

interface IntercomBootConfig {
  // Required Intercom fields
  app_id: string;
  app_name: string;
  // User identifiers
  person_id: string | number;
  account_id: string | number;
  location_id: string;
  // User information
  email: string;
  name: string;
  // User permissions/metadata
  role: string;
  seat_type: string;
  // App information
  desktop_version: string;
}

/**
 * Initiates an Intercom survey with the specified ID
 * @param surveyId - The numeric identifier of the survey to start
 */
export const startSurvey = (surveyId: number) => {
  window?.Intercom?.('startSurvey', surveyId);
};

/**
 * Initializes the Intercom with the standard intercomSettings object.
 * @param person - User information including ID, account details, and personal data
 * @param jwt - JSON Web Token containing access control level and location ID
 * @see https://developers.intercom.com/installing-intercom/web/methods#intercomboot-intercomsettings
 */
export const boot = (
  person: IPersonSignInTokenResponse,
  jwt: { acl: string; location_id: string }
) => {
  const { desktop } = extractVersions(navigator.userAgent);

  window?.Intercom?.('boot', {
    app_id: INTERCOM_SETTINGS.appId,
    app_name: 'communicator', //app description
    person_id: person.id, // "person_id" in JWT
    account_id: person.account.id, // "account_id" in JWT
    location_id: jwt.location_id, // "location_id" in JWT
    email: person.email, // User's email
    name: `${person.firstName} ${person.lastName}`, // User's name
    role: jwt.acl,
    seat_type: person.package, // "acctp" in JWT
    desktop_version: desktop.formatted,
  } satisfies IntercomBootConfig);
};

/**
 * Updates the Intercom messenger instance
 * Call this when you want to update the messenger after user data changes
 */
export const update = () => {
  window?.Intercom?.('update');
};

/**
 * Terminates the Intercom messenger instance
 * Call this when cleaning up or when the user logs out
 */
export const shutdown = () => {
  window?.Intercom?.('shutdown');
};
