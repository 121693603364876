import cx from 'classnames';
import AtMentionMembers from 'Components/AtMentionMembers';
import {
  AttachmentsContainer,
  AttachmentsRef,
} from 'Components/Chat/ChatFooter/AttachmentsContainer';
import { ChatLayoutContext } from 'Components/Chat/contexts/ChatLayoutContext';
import { useAttachments } from 'Components/Chat/hooks/attachments/useAttachments';
import ContentEditable from 'Components/ContentEditable';
import { EmojiButton } from 'Components/shared/EmojiButton';
import { Icon } from 'Components/shared/Icon';
import { IconButton } from 'Components/shared/IconButton';
import { MENTION_PREFIX } from 'Constants/enums';
import { SHOW_FILE_UPLOAD } from 'Constants/env';
import useCheckLongMessage from 'Hooks/longMessage/useCheckLongMessage';
import { isEmpty } from 'lodash';
import { MobXProviderContext, observer } from 'mobx-react';
import { ContactModelBase } from 'Models/ContactModel';
import { usePersonStore } from 'Modules/person/index.store';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { isNumber } from 'util';
import { pushToGTMDataLayer } from 'Utils/analytics';
import {
  findMentionInsertData,
  focusContentEditable,
  getSelectionRange,
  insertEmoji,
  placeCursorAtEndOfElement,
  placeCursorAtSpecificPosition,
} from 'Utils/inputNodeUtils';
import { keyIsAlphanumeric, keyIsValidFilterInput } from 'Utils/keyboardInput';
import {
  handleAlphanumericWhenMentionListOpen,
  handleAtSymbol,
  handleBackspaceDeleteWhenMentionListOpen,
  handleEnterKey,
  handleEscapeKey,
  handleTabRightArrowSpacebarWhenMentionListOpen,
  handleUpArrowEditOnEmptyInput,
  handleUpDownLeftArrowsWhenMentionListOpen,
} from 'Utils/mentionKeyHandlers';
import { resolveConversationPath } from 'Utils/routeNav';
import { turndownSvc } from 'Utils/turndownSvc';
import { Styled } from './index.styles';
import { MessageBanner } from './MessageBanner';
import { MessageBannerProps } from './MessageBanner/index.types';

export const MessageInputArea: React.FC = observer(() => {
  const { participant, pusher, person, message, ui, conversation } =
    React.useContext<RootStoreProps>(MobXProviderContext);
  const { setView, replyMessage, setReplyMessage } =
    React.useContext(ChatLayoutContext);

  const location = useLocation();
  const { conversationId = '' } = useParams();

  const { isAvailablePhoneNumber } = usePersonStore();

  const [controlPressed, setControlPressed] = React.useState(false);
  const [canReceiveSMS, setCanReceiveSMS] = React.useState(true);
  const [userSMSErrorMessage, setUserSMSErrorMessage] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [showUploadInfoBanner, setShowUploadInfoBanner] = useState(false);
  const [returnPressed, setReturnPressed] = useState(false);
  const { messageIsTooLong, checkIsMessageTooLong, resetMessageIsTooLong } =
    useCheckLongMessage();

  const contentEditableRef = useRef<ContentEditable>(null);

  const {
    addAttachmentsViaEvent,
    getAttachmentsCount,
    hasUploadErrors,
    hasUploadsInProgress,
    uploadedS3ObjectsMap,
    resetAttachments,
  } = useAttachments();

  const filePickerRef = useRef<AttachmentsRef>(null);

  useEffect(() => {
    if (conversationId === '0' || location.pathname === '/chat/adHocGroup') {
      return;
    }
    getSMS();
  }, []);

  useEffect(() => {
    if (conversationId === '0' || location.pathname === '/chat/adHocGroup') {
      return;
    }

    setCanReceiveSMS(true);
    getSMS();

    if (!ui.cursorPosition) {
      placeCursorAtEndOfElement(contentEditableRef.current?.htmlEl);
      ui.setCursorPosition(contentEditableRef.current?.htmlEl.innerText.length);
    }
  }, [conversationId]);

  useEffect(() => {
    replyMessage && focusContentEditable(contentEditableRef.current);
  }, [replyMessage]);

  useEffect(() => {
    if (returnPressed && hasUploadsInProgress) {
      setShowUploadInfoBanner(true);
    } else {
      setShowUploadInfoBanner(false);
      setReturnPressed(false);
    }
  }, [returnPressed, hasUploadsInProgress]);

  const showSendError = ui.userSmsCantSendError.get(conversationId) === 'false';

  const isGroupChat =
    conversation.CurrentConversation?.grouping === 'Channel' ||
    conversation.CurrentConversation?.grouping === 'Group';

  const isSMSConversation = !!participant
    .selectOtherParticipants(conversationId)
    ?.some(({ phone }) => phone);

  const emojiPickerOpenState =
    ui.getEmojiPickerState().open && !ui.getEmojiPickerState().editing;

  let isDeadkeyPressed = false;
  let previousKeyWasDeadKey = false;

  /**
   * Check if the message is too long, and clean the input if it is the right size.
   * @param text The text to validate.
   * @returns Returns true if message is too long, and false if it isn't.
   */
  const checkLongMessageAndClean = useCallback(
    (text: string) => {
      if (checkIsMessageTooLong(text)) {
        return true;
      }

      message.setMessageDraftRaw(conversationId, '');
      message.setMessageDraftHtml(conversationId, '');
      cleanContentEditable();

      return false;
    },
    [conversationId, message]
  );

  const handleClick = async (e) => {
    // If online, send the message and reset the input
    await handleEnterKey(
      e,
      pusher.isOnline,
      conversationId,
      message.createMessageMentionListOpen,
      message.createMessageMentionSelectedParticipantId,
      insertMentionAtCursor,
      participant.selectOtherParticipantPersons,
      sendMessage
    );
    setView('');
    focusContentEditable(contentEditableRef.current, undefined, true);
  };

  const sendMessage = async () => {
    let sendAsSMS =
      participant.conversationMessagesSMS.get(conversationId) || false;
    const otherParticipants =
      participant.selectOtherParticipants(conversationId);
    const rawMessageContent = message.getMessageDraftRaw(conversationId);

    if (rawMessageContent?.length > 0 || getAttachmentsCount) {
      // This is an extra check for sending SMS messages, if the other user is not pro
      if (otherParticipants?.length === 1) {
        // Check to see if the other participant is a standard or a pro user.
        // otherPerson is null if it's just a phone number
        const otherPersonId =
          parseInt(otherParticipants[0].id) || otherParticipants[0].personId;
        const otherPerson = otherPersonId
          ? person.selectPersonValueById(otherPersonId)
          : null;
        if (otherPerson !== null) {
          if (!otherPerson.data.SupportsChat) {
            sendAsSMS = true;
          }
        } else {
          sendAsSMS = true;
        }
      }

      // Now send the message as either SMS or Regular
      if (location.pathname.includes('adHocGroup')) {
        if (
          conversation.adHocGroupParticipants.every(
            (participant) => participant instanceof ContactModelBase
          )
        ) {
          sendAsSMS = true;
        }

        if (checkLongMessageAndClean(rawMessageContent)) {
          return;
        }

        void conversation.createConversationFromAdHoc(
          conversation.adHocGroupParticipants.map(({ id }) => id as string),
          rawMessageContent,
          sendAsSMS,
          uploadedS3ObjectsMap
        );
      } else {
        const messageContent =
          !rawMessageContent.length && !replyMessage
            ? null
            : {
                text: rawMessageContent,
                text_v2: `${
                  replyMessage ? `@ref_{${replyMessage.id}} ` : ''
                }${rawMessageContent}`,
              };

        if (
          checkLongMessageAndClean(
            sendAsSMS ? rawMessageContent : messageContent?.text_v2
          )
        ) {
          return;
        }

        await message.createMessagePost(
          conversationId,
          sendAsSMS ? null : messageContent,
          sendAsSMS ? { text: rawMessageContent } : null,
          conversation.CurrentConversation.grouping,
          uploadedS3ObjectsMap,
          replyMessage
        );
      }

      if (
        message
          .getMessageDraftHtml(conversationId)
          .includes('class="input-mention"')
      ) {
        pushToGTMDataLayer('mention', { conversationId });
      }
      const objDiv = document.querySelector('.context-content-items-list');
      if (objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }

      setReplyMessage(null);
      resetAttachments();
    }
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === 'Control' && setControlPressed(false);
    e.key === 'Dead' && (isDeadkeyPressed = false);
  };

  const onKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Control') {
      setControlPressed(!controlPressed);
    } else if (e.key !== 'Enter') {
      setControlPressed(false);
    }
    if (previousKeyWasDeadKey && !isDeadkeyPressed) {
      previousKeyWasDeadKey = false;
      return;
    }

    if (e.key === 'Dead') {
      isDeadkeyPressed = true;
      previousKeyWasDeadKey = true;
    }

    // Enter
    if (e.key === 'Enter') {
      // prevent repeated submission if user holds down the enter key
      if (e.repeat === true || ui.sendingMessage) {
        e.preventDefault();
        return;
      }

      if (controlPressed) {
        const value = contentEditableRef.current.lastHtml;
        updateMessage(value + '\n\r');
        return;
      }

      if (hasUploadsInProgress || hasUploadErrors) {
        setReturnPressed(true);
        e.preventDefault();
        return;
      }

      await handleEnterKey(
        e,
        pusher.isOnline,
        conversationId,
        message.createMessageMentionListOpen,
        message.createMessageMentionSelectedParticipantId,
        insertMentionAtCursor,
        participant.selectOtherParticipantPersons,
        sendMessage
      );
      setView('');
      focusContentEditable(contentEditableRef.current);
    }

    if (e.key === 'Escape') handleEscapeKey(e, ui.setEmojiPickerState);

    // Up arrow without shift key, while the new message input is empty, and the mention list is not open
    if (
      e.key === 'ArrowUp' &&
      !e.shiftKey &&
      (isGroupChat || !message.createMessageMentionListOpen) &&
      isEmpty(message.getMessageDraftRaw(conversationId))
    ) {
      handleUpArrowEditOnEmptyInput(
        e,
        conversation.CurrentConversation,
        participant.selectOtherParticipantPersons,
        message.newestMessageOwnedByUserId,
        message.setEditMessageDraftRaw,
        message.setEditMessageDraftHtml,
        message.isEditingMessageId,
        message.setIsEditingMessageId
      );
    }

    // @ symbol (shift + 2), pop up the mentions list
    if (isGroupChat && e.key === '@' && !isSMSConversation) {
      handleAtSymbol(
        e,
        contentEditableRef.current.selectionRange,
        message.getMessageDraftHtml(conversationId),
        message.createMessageMentionListOpen,
        message.setCreateMessageMentionListOpen,
        message.setCreateMessageMentionSelectedParticipantId,
        ui.setEmojiPickerState
      );
    }
    // Handle Up/Down arrows, Backspace, and Delete when the Mention list is open
    if (isGroupChat && message.createMessageMentionListOpen) {
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft'].includes(e.key)) {
        handleUpDownLeftArrowsWhenMentionListOpen(
          e,
          participant.selectFilteredOtherParticipantPersonsInCurrentConversation,
          message.createMessageMentionSelectedParticipantId,
          message.setCreateMessageMentionSelectedParticipantId,
          message.setCreateMessageMentionListOpen,
          message.createMessageMentionFilter
        );
      } else if (['ArrowRight', 'Tab', ' '].includes(e.key)) {
        handleTabRightArrowSpacebarWhenMentionListOpen(
          e,
          message.createMessageMentionSelectedParticipantId,
          participant.selectOtherParticipantPersons,
          conversationId,
          insertMentionAtCursor
        );
      } else if (['Backspace', 'Delete'].includes(e.key)) {
        handleBackspaceDeleteWhenMentionListOpen(
          e,
          contentEditableRef.current.selectionRange,
          message.getMessageDraftHtml(conversationId),
          message.createMessageMentionListOpen,
          message.setCreateMessageMentionListOpen,
          message.createMessageMentionFilter,
          message.setCreateMessageMentionFilter,
          participant.selectFilteredOtherParticipantPersonsInCurrentConversation,
          message.setCreateMessageMentionSelectedParticipantId
        );
      } else if (keyIsAlphanumeric(e)) {
        handleAlphanumericWhenMentionListOpen(
          e,
          message.createMessageMentionListOpen,
          message.createMessageMentionFilter,
          message.setCreateMessageMentionFilter,
          participant.selectFilteredOtherParticipantPersonsInCurrentConversation,
          message.setCreateMessageMentionSelectedParticipantId
        );
      }
      // Any invalid inputs should close the Mention list to avoid auto-complete desync
      else if (!keyIsValidFilterInput(e)) {
        message.setCreateMessageMentionListOpen(false);
      }
    }
  };

  const getSMS = () => {
    if (
      isEmpty(participant.selectLoggedInUserParticipant(conversationId)) ||
      isEmpty(conversation.CurrentConversation)
    )
      return;

    if (
      isSMSConversation &&
      conversation.CurrentConversation?.grouping === 'OneOnOne'
    ) {
      const otherParticipants =
        participant.selectOtherParticipants(conversationId);
      // SMS messages can only be sent to OneOnOne Chats that have number Or they are Pro Users
      // Check to see if the other participant is a standard or a pro user.
      // otherPerson is null if it's just a phone number
      if (otherParticipants?.length <= 0) return;

      if (!otherParticipants[0].personId) return setErrIfUserCantReceiveSMS();
      const otherPerson = person.selectPersonValueById(
        otherParticipants[0].personId || parseInt(otherParticipants[0].id)
      );
      if (otherPerson === null) return setErrIfUserCantReceiveSMS();
      if (!otherPerson.data.SupportsChat) {
        setCanReceiveSMS(false);
        setUserSMSErrorMessage(false);
      }
    }
  };

  const setErrIfUserCantReceiveSMS = () => {
    const loggedInUser = person.selectPersonValueById(person.loggedInPersonId);
    // Check for when messaging a non-participant (mobile number)
    // Check if logged in user has SMS capability/direct number
    if (!loggedInUser.data.canReceiveSMS) {
      setCanReceiveSMS(false);
      setUserSMSErrorMessage(true);
    }
  };

  const onHover = () => !canReceiveSMS && setIsHover(true);
  const offHover = () => setIsHover(false);

  const toggleShowEmojis = (open: boolean) => {
    if (open) {
      ui.setEmojiPickerState({ open: true, editing: false });
      message.setCreateMessageMentionListOpen(false);
      if (contentEditableRef.current?.htmlEl) {
        const contentEditableRefSelection = getSelectionRange(
          contentEditableRef.current?.htmlEl
        );
        // Focus and move to end if there is no selection (unfocused) in the input
        if (
          contentEditableRefSelection.start === 0 &&
          contentEditableRefSelection.end === 0
        ) {
          focusContentEditable(contentEditableRef.current);
          placeCursorAtEndOfElement(contentEditableRef.current?.htmlEl);
        }
      }
    } else {
      ui.setEmojiPickerState({ open: false, editing: false });
    }
  };

  const updateMessage = (inputValue: string) => {
    resetMessageIsTooLong();

    if (inputValue) {
      inputValue = inputValue
        .replace(/(<p>|<br>)/gi, '\n')
        .replace(/<\/p>/gi, '')
        .replace(/&amp;/gi, '&');
    }

    let rawResultValue = inputValue.trim();
    if (inputValue.includes('class="input-mention"')) {
      rawResultValue = turndownSvc.turndown(inputValue.replace(/\n/gi, '<br>'));
    }
    rawResultValue = rawResultValue
      .replace(/&lt;/gi, '<')
      .replace(/&gt;/gi, '>');
    if (!isDeadkeyPressed) {
      message.setMessageDraftRaw(conversationId, rawResultValue);
      message.setMessageDraftHtml(
        conversationId,
        rawResultValue ? inputValue : ''
      );
    }
  };

  const insertMentionAtCursor = (prefix: MENTION_PREFIX, value: string) => {
    let messageDraftRaw = message.getMessageDraftRaw(conversationId);
    let messageDraftHtml = message.getMessageDraftHtml(conversationId);
    const { start, end, htmlStart, htmlEnd } =
      contentEditableRef.current.selectionRange;

    const rawInsertData = findMentionInsertData(messageDraftRaw, start);
    messageDraftRaw =
      messageDraftRaw.substring(0, rawInsertData.insertIndex) +
      `${rawInsertData.prependWith}${prefix}${value} ` +
      messageDraftRaw.substring(end);
    message.setMessageDraftRaw(conversationId, messageDraftRaw);

    if (prefix === '@pr') {
      const personId = parseInt(value, 10);
      if (isNumber(personId)) {
        const participantPersons =
          participant.selectParticipantPersons(conversationId); // Can't be null at this point (render function doesn't include Mentions unless it is not null)
        const mentionedPerson = participantPersons.find(
          (pp) => pp.person.id === personId
        );
        const htmlInsertData = findMentionInsertData(
          messageDraftHtml,
          htmlStart
        );
        messageDraftHtml =
          messageDraftHtml.substring(0, htmlInsertData.insertIndex) +
          `${htmlInsertData.prependWith}<div class="input-mention" mention-target="${prefix}${value} " contenteditable="false">@${mentionedPerson.person.firstName} ${mentionedPerson.person.lastName}</div>&nbsp;` +
          messageDraftHtml.substring(htmlEnd);
        message.setMessageDraftHtml(conversationId, messageDraftHtml);
        message.setCreateMessageMentionListOpen(false);
      }
    } else {
      const htmlInsertData = findMentionInsertData(messageDraftHtml, htmlStart);
      messageDraftHtml =
        messageDraftHtml.substring(0, htmlInsertData.insertIndex) +
        `${htmlInsertData.prependWith}<div class="input-mention" mention-target="${prefix}${value} " contenteditable="false">${prefix}${value}</div>&nbsp;` +
        messageDraftHtml.substring(htmlEnd);
      message.setMessageDraftHtml(conversationId, messageDraftHtml);
      message.setCreateMessageMentionListOpen(false);
    }
    const startPosition =
      contentEditableRef.current.selectionRange.start +
      value.length +
      prefix.length +
      1;
    contentEditableRef.current.selectionRange = {
      ...contentEditableRef.current.selectionRange,
      start: startPosition,
      end: startPosition,
    };
  };

  const fileImportEvent = async (e: any): Promise<void> => {
    await addAttachmentsViaEvent(e);
  };

  const invokeFilePicker = () => {
    filePickerRef.current?.triggerFilePicker();
    focusContentEditable(contentEditableRef.current);
  };

  const cleanContentEditable = () => {
    contentEditableRef.current.lastHtml = '';
  };

  const isFileUploadActive =
    SHOW_FILE_UPLOAD && (!isSMSConversation || isAvailablePhoneNumber);

  const messageTooLongBanner: MessageBannerProps = {
    canClose: false,
    text: 'It seems that your message is too long. Please shorten it.',
  };

  const uploadInfoBanner: MessageBannerProps = {
    icon: 'info',
    text: `Sit tight, we’re uploading your file${
      getAttachmentsCount > 1 ? 's' : ''
    }`,
  };

  const showMessageBanner = messageIsTooLong || showUploadInfoBanner;

  const getDisabledButtonTitle = useCallback(() => {
    if (messageIsTooLong) return 'Shorten your message first';
    if (hasUploadsInProgress) return "Sit tight, we're uploading your file";
    if (hasUploadErrors)
      return 'Error uploading files to this conversation, try uploading the files again';
    if (isSMSConversation && !isAvailablePhoneNumber && !!getAttachmentsCount) {
      return "MMS feature isn't enabled on your account, please remove any attached file to send your message";
    }
    return '';
  }, [
    messageIsTooLong,
    hasUploadsInProgress,
    hasUploadErrors,
    isSMSConversation,
    isAvailablePhoneNumber,
    getAttachmentsCount,
  ]);

  return (
    <Styled.MessageInputArea
      id="message-input"
      onClick={(e) => e.stopPropagation()}
    >
      {!canReceiveSMS && isHover && (
        <Styled.MessageInfo onMouseEnter={onHover} onMouseLeave={offHover}>
          {!userSMSErrorMessage
            ? 'The user that you are trying to message does not currently have a number that can receive SMS.'
            : 'To enable SMS, please request a phone number from your account admin.'}
        </Styled.MessageInfo>
      )}
      {showSendError && (
        <Styled.MessageInfo onMouseEnter={onHover} onMouseLeave={offHover}>
          The user that you are trying to message does not currently have a
          number that can receive SMS.
        </Styled.MessageInfo>
      )}
      <Styled.MessageBar largeMargin={showMessageBanner}>
        <Styled.InputAndBannerWrapper>
          <MessageBanner
            {...(messageIsTooLong
              ? messageTooLongBanner
              : showUploadInfoBanner
              ? uploadInfoBanner
              : {})}
            isVisible={showMessageBanner}
          />

          <Styled.InputWrapper
            onMouseEnter={onHover}
            onMouseLeave={offHover}
            onKeyDown={(e) =>
              e.key === 'Escape' && handleEscapeKey(e, ui.setEmojiPickerState)
            }
          >
            {replyMessage && (
              <Styled.ReplyBox
                reference={replyMessage}
                onRemoveClick={() => setReplyMessage(null)}
              />
            )}
            <ContentEditable
              {...{ fileImportEvent, onKeyDown, onKeyUp }}
              ref={contentEditableRef}
              className={cx('ce-new-message _lr-hide', {
                'ce-disabled': !canReceiveSMS,
              })}
              placeholder="Send a Message"
              html={message.getMessageDraftHtml(conversationId)}
              onChange={(e) => updateMessage(e.target.value)}
              disabled={!canReceiveSMS}
              onBlur={() =>
                !message.isEditingMessageId &&
                ui.setCursorPosition(
                  contentEditableRef.current.selectionRange.start
                )
              }
              onFocus={() =>
                placeCursorAtSpecificPosition(
                  contentEditableRef.current.htmlEl,
                  ui.cursorPosition
                )
              }
              updateMessage={updateMessage}
            />
            {conversationId !== '0' &&
              conversation.CurrentConversation?.id === conversationId &&
              isGroupChat &&
              participant.selectOtherParticipantPersons(conversationId) !==
                null && ( // Only possible to Mention if the list of others has loaded
                <AtMentionMembers
                  {...{ conversationId, insertMentionAtCursor, onKeyDown }}
                  contentEditableRef={contentEditableRef}
                  getEmojiPickerState={ui.getEmojiPickerState}
                  key="at-mention-members"
                  loggedInPersonId={person.loggedInPersonId}
                  mentionListOpen={message.createMessageMentionListOpen}
                  messageMentionFilter={message.createMessageMentionFilter}
                  resolveConversationLinkPath={(path: string) =>
                    resolveConversationPath(location.pathname, path)
                  }
                  selectedMentionParticipantId={
                    message.createMessageMentionSelectedParticipantId
                  }
                  selectFilteredOtherParticipantPersonsInCurrentConversation={
                    participant.selectFilteredOtherParticipantPersonsInCurrentConversation
                  }
                  selectParticipantPersons={
                    participant.selectParticipantPersons
                  }
                  selectPersonPresenceStatus={ui.selectPersonPresenceStatus}
                  selectUnreadCounts={ui.selectConversationUnreadCounts}
                  setConversationAndTotalUnreadCount={
                    ui.setConversationAndTotalUnreadCount
                  }
                  updateMyLastReadMessage={participant.updateMyLastReadMessage}
                  setMentionListOpen={message.setCreateMessageMentionListOpen}
                  setMessageMentionFilter={
                    message.setCreateMessageMentionFilter
                  }
                  setSelectedMentionParticipantId={
                    message.setCreateMessageMentionSelectedParticipantId
                  }
                  setEmojiPickerState={ui.setEmojiPickerState}
                />
              )}
            {isFileUploadActive ? (
              <div className="attach-wrapper">
                <IconButton
                  onClick={invokeFilePicker}
                  icon="attachment"
                  size="tiny"
                  testid="chat-button-add-attachment"
                />
              </div>
            ) : (
              <Popup
                trigger={
                  <div className="attach-wrapper disable-attachment">
                    <Icon icon="attachment" size="tiny" />
                  </div>
                }
                content="You are unable to send files - MMS feature is not yet available."
                position="top center"
              />
            )}
            <EmojiButton
              isOpen={emojiPickerOpenState}
              onOpen={() => toggleShowEmojis(true)}
              onClose={() => toggleShowEmojis(false)}
              onChooseEmoji={(emoji) =>
                insertEmoji(emoji, contentEditableRef.current)
              }
            />
          </Styled.InputWrapper>
        </Styled.InputAndBannerWrapper>
        <Styled.SubmitIconButton
          icon="send"
          onClick={handleClick}
          size="medium"
          testid="messageInputArea-buttonSubmitMessage"
          variant="primary"
          loading={ui.sendingMessage}
          disabled={{
            condition:
              messageIsTooLong ||
              hasUploadsInProgress ||
              hasUploadErrors ||
              (isSMSConversation &&
                !isAvailablePhoneNumber &&
                !!getAttachmentsCount),
            title: getDisabledButtonTitle(),
          }}
        />
      </Styled.MessageBar>
      {SHOW_FILE_UPLOAD && (
        <React.Suspense fallback={<div></div>}>
          <AttachmentsContainer ref={filePickerRef} />
        </React.Suspense>
      )}
      {!pusher.isOnline && (
        <div className="p-notification-bar-offline">
          Sorry, we can't reach our servers, so messages can't be sent right
          now.
        </div>
      )}
    </Styled.MessageInputArea>
  );
});
